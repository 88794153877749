import React from "react"
import styled from "styled-components"
import { breakpoints, spacers } from "../../styles/variables"
import RoundedImage from "../RoundedImage"
import Text from "./Text"
import Button from "../Button"


const TextDiv = styled.article`
    width: 100%;
    p {
        margin-bottom: ${spacers.s}px;
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        p {
            margin-bottom: 26px;
        }
        img {
            float: left;
        }

        .mobileDisplayNone {
            > div > img {
                margin: 0 25px 10px 0;
            } 
        }
    }

    

`


const TextWithImage = ({kurs}) => <TextDiv>
    <Text>
        <div className="mobileDisplayNone">
            <RoundedImage image={kurs.image} contentImage />
        </div>
        <p>
            <strong dangerouslySetInnerHTML={{__html: kurs.lead}} />
        </p>
    <div className="tdDisplayNone">
        <RoundedImage image={kurs.image} contentImage />
        {/* grösse korrigieren */}
    </div>

    <p dangerouslySetInnerHTML={{__html: kurs.beschreibung}} />
    <div className="mobileDisplayNone">
        <Button link="/kontakt" text="Jetzt anmelden" />
    </div>
</Text>

</TextDiv>

export default TextWithImage