import React from "react"

import DefaultLayout from "../layouts/default"

import Kurs from "../components/kurs"

import { kurse } from "../data/kurse"
import Text from "../components/text/Text"
import DottedLineHorizontal from "../components/DottedLineHorizontal"
import { Link } from "gatsby"

const active = {
  title: "Weiterbildung"
}

const Weiterbildung = () =>
  <DefaultLayout image="/assets/images/06CF5E6D.jpg" imageTitle={{
    title: "Natur, Wahrnehmung, Gestaltung und Prozess",
    subTitle: "Naturerfahrungen in Verbindung mit Malen und Gestalten"
  }} active={active}>
    <Text>
      <h1>Hinweise</h1>
      <p>
        Alle Workshops finden in Mogelsberg statt, das ab St. Gallen in 20 Minuten mit dem Zug direkt erreichbar ist. Es gibt – falls gewünscht – im Dorf Übernachtungsmöglichkeiten.
      </p>
      <p>
        Die Workshops finden bei jeder Witterung statt - mehrheitlich draussen oder wenn erforderlich in meinem Kursraum, der ungeheizt ist. Angepasste Kleidung und gutes Schuhwerk sind erforderlich (Wetter, Wärme und Farbe).
      </p>
      <p>
        Anmelden kannst du dich über <a href="mailto:info@strich.sg">info@strich.sg</a> oder per Telefon <a href="tel:+41 79 844 04 07">079 844 04 07</a>. <br />Wenn du Fragen hast, melde dich ebenfalls einfach über Email oder Telefon.
      </p>
      <p>
        Beachte bitte die <Link to="/agb">AGB</Link> auf dieser Website, die für die Kurse gelten.
      </p>
      <DottedLineHorizontal />
      <br /><br />
    </Text>
    {kurse.map((kurs, index) => <Kurs kurs={kurs} key={index} />)}

  </DefaultLayout>

export default Weiterbildung