import React from "react"
import styled from "styled-components"
import { breakpoints } from "../../styles/variables"

const TwoThirdsDiv = styled.section`
    width: 100%;
    margin-bottom: 50px;
    @media screen AND (min-width: ${breakpoints.m}px) {
        display: grid;
        grid-template-columns: 2fr 50px 1fr;
        margin-bottom: 60px;
    }
`

const TwoThirdsGridReversed = ({children}) => <TwoThirdsDiv>{children}</TwoThirdsDiv>

export default TwoThirdsGridReversed