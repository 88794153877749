import React from "react"
import styled from "styled-components"

import {breakpoints} from "../styles/variables"

const ButtonElem = styled.a`
    font: 18px / 22px "Karla Medium", sans-serif;
    padding: 8px 40px 7px 31px;
    border: 1px solid black;
    border-radius: 100px;
    color: white;
    background: black;
    transition: all 100ms;
    display: inline-block;
    margin-bottom: 60px;

    &:hover {
        color: black;
        background: white;
        text-decoration: none;
    }

    @media screen AND (min-width: ${breakpoints.m}px) {
        margin-bottom: 0;
        margin-top: 26px;

    }
    @media screen AND (min-width: ${breakpoints.l}px) {
        font: 24px / 28px "Karla Medium", sans-serif;
        padding: 10px 38px 9px 39px;
        
    }
`

const Button = ({text, link}) => <ButtonElem href={link}>
    {text}
</ButtonElem>

export default Button