export const kurse = [
  // {
  //     uebertitel: "Workshop 3 Halbtage",
  //     titel: "Ecodyeing und Sticken",
  //     lead: `Ecodyeing ist eine Technik, bei der in einem alchemistischen Verfahren durch Wärme und Feuchtigkeit die Pflanze ihre Farbe direkt auf Papier oder Stoff überträgt.`,
  //     beschreibung: `In diesem Workshop werden am ersten Halbtag nach einem Sammelspaziergang mit Pflanzenteilen Stoffe gefärbt. Die anderen zwei Nachmittage werden die Stoffe zusammengestellt und mit pflanzengefärbten Garnen bestickt. An den drei Halbtagen werden die einzelnen Schritte mit ergänzendem Wissen für diese Färbetechnik und Stickgrundlagen vermittelt. Alle Teilnehmer*innen fertigen eine individuelle Arbeit.`,
  //     sidebar: `
  //     <p>
  //         <strong>Kursdaten</strong><br />
  //         Mo. 16./23./30. Mai 2022 <br />
  //         jeweils von 13.30 – 17 Uhr<br />
  //         Fr. 26. August und 2./9. September 2022 <br />
  //         jeweils von 9 – 12.30 Uhr
  //     </p>
  //     <p>
  //         <strong>Kosten</strong><br />
  //         Fr. 210.— für alle drei Halbtage plus Fr. 25.- für Material
  //     </p>
  //     <p>
  //         <strong>Leitung</strong><br />
  //         strichpunkt | Evelyn Krapf-Schibli<br />
  //         Mal- und Gestaltungstherapeutin i.A.<br />
  // 		Psychosoziale Beratung MSc<br />
  //         Heilpädagogik MAS<br />
  //         Lehrerin für Textiles Werken
  //     </p>
  //     <p>
  //         <strong>Voraussetzung</strong><br />
  //         keine
  //     </p>`,
  //     image: "/assets/images/9726EAF5.jpg",
  // },

  // {
  //     uebertitel: "Workshop 3 Tage",
  //     titel: "Stoff färben, bedrucken und besticken",
  //     lead: `Dieser Workshop ist eine Erweiterung des dreitätigen Kurses Pflanzendruckverfahren. Am ersten Tag sammeln wir bei einem Spaziergang einige Blätter und Blüten und färben Stoffstücke mit der Ecodyeing-Technik. Am zweiten Tag werden die Stoffe mit verschiedenen Techniken bedruckt und am dritten Tag werden die vorbereiteten Stoffe zusammengestellt und bestickt.`,
  //     beschreibung: `Mit allen im Kurs gezeigten Techniken entstehen interessante Druckergebnisse; der Abdruck auf dem Stoff bringt Überraschungen und spannende Effekte. Bei einigen lassen sich weitere Schichten auftragen, die eher transparent oder dann deckend sind, wodurch einzelne Stellen zurücktreten oder stärker betont werden. Durch die Weiterbearbeitung mit Nadel und Garn lassen sich die Stoffstücke verbinden und Akzente setzen.
  //     <br /><br />
  //     In diesem Workshop werdet ihr die Grundlagen unterschiedlicher Drucktechniken erweitern und das Färben mit Pflanzen anwenden. An den drei Tagen werden die einzelnen Schritte mit ergänzendem Wissen für das Färben mit Naturmaterialien und Stickgrundlagen vermittelt. Alle Teilnehmer*innen fertigen eine individuelle Arbeit.
  //     <br /><br />
  //     Blätter und Blüten sammeln wir während des Workshops auf einem Spaziergang in der Umgebung.
  //     `,
  //     sidebar: `
  //     <p>
  //         <strong>Kursdaten</strong><br />
  //         13. Juni und 4./11. Juli 2022<br />
  //         jeweils von 9 – 16 Uhr<br />
  //         16./17./18. September 2022<br />
  //         Fr. und Sa. von 9 – 16.30 Uhr, So. von 9 bis 15.30 Uhr
  //     </p>
  //     <p>
  //         <strong>Kosten</strong><br />
  //         Fr. 390.—für alle drei Tage, inkl. Material (ohne Mittagessen)
  //     </p>
  //     <p>
  //         <strong>Leitung</strong><br />
  //         strichpunkt | Evelyn Krapf-Schibli<br />
  //         Mal- und Gestaltungstherapeutin i.A.<br />
  // 		Psychosoziale Beratung MSc<br />
  //         Heilpädagogik MAS<br />
  //         Lehrerin für Textiles Werken
  //     </p>
  //     <p>
  //     <strong>Voraussetzungen</strong><br />
  //     Kurs Pflanzendruckverfahren oder Druckkenntnisse
  //     </p>`,
  //     image: "/assets/images/IMG_0141.jpeg",
  // },
  {
    uebertitel: "Workshop 2 Tage",
    titel: "Regeneration in der Natur",
    lead: `Die Natur bietet Raum für Erleben und Erfahren, für Regeneration und Ressoucenstärkung. Die Basis des Kurses bilden ein vertieftes Wahrnehmen der Umgebung, ein lebendiges Annähern und in Beziehungtreten mit der Umwelt. Die bewusste Präsenz in der Gegenwart unterstützt innere Ruhe und Wohlbefinden zu empfinden und daraus Kraft zu entwickeln - gerade, wenn Menschen durch Stress belastet sind oder sich in widrigen Lebensumständen befinden. Angeregt werden ein Zugang zum Lebendigen, die Regeneration des Aufmerksamkeits- und Konzentrationsvermögens und die Unterstützung des Wohlbefindens.`,
    beschreibung: `In diesem Kurs erfährst du, wie in der Natur Kraft für den Alltag gefunden und wie diese Momente bewahrt werden können, um diese später in herausfordernden Situationen reaktivieren zu können. Menschen wachsen eingebettet in einer (mehr oder weniger) natürlichen Umwelt bzw. in Ökologien auf, diese Erfahrungen sind im Körper und im Gedächtnis gespeichert und beeinflussen die Biographie. <br /><br />

    Wir sind zwei Tage in einem Wechselspiel zwischen Wahrnehmung, Bewegung und Sein unterwegs. Im Zentrum steht das Ausprobieren kunsttherapeutischer Interventionen, das Erfahren und Reflektieren ihrer Wirkweisen und die Entwicklung von Anwendungsideen. Das Gestalten als kreativer Ausdruck und der Austausch in der Gruppe unterstützen den Selbsterfahrungsprozess und den Transfer in die Praxis.<br /><br />
    
    Die Auseinandersetzung mit der Natur findet über mal- und gestaltungstherapeutische Methoden, über Achtsamkeits- und Imaginationsübungen statt.`,
    sidebar: `
        <p>
            <strong>Kursziele</strong><br />
            Die Teilnehmenden<br />
            <ul style="list-style-position: inside; list-style-type: circle">
                <li>entdecken ökologische Einflüsse auf die eigene Biographie</li>
                <li>entwickeln ein Verständnis für die Bedeutung von kreativen Naturinterventionen in persönlichen Krisen</li>
                <li>erproben und erfahren kunsttherapeutische Interventionen und Übungen in der Natur und reflektieren den Transfer in die eigene berufliche Praxis</li>
            </ul>
        </p>
        <p>
            <strong>Kursdaten</strong><br />
            Datum für 2025 folgt<br />
            2 Tage jeweils von 9 bis 16 Uhr<br />
        </p>
        <p>
            <strong>Kosten</strong><br />
            Fr. 300.— für zwei Tage, inkl. Material (ohne Essen)
        </p>
        <p>
            <strong>Leitung</strong><br />
            Strichpunkt | Evelyn Krapf-Schibli<br />
            Kunsttherapeutin iac Fachrichtung Mal- und Gestaltungstherapie<br />
            Psychosoziale Beratung MSc<br />
            Heilpädagogik MAS<br />
            Lehrerin für Textiles Werken
        </p>
        <p>
        <strong>Zielgruppe</strong><br />
        Fachpersonen aus der Kunsttherapie und dem psychosozialen Bereich, die den Aspekt Natur mit kreativen Mitteln in ihre Beratung und Therapie einbringen möchten.<br />
        Die Weiterbildung wird vom EMR für Weiterbildung im Umfang von 12 Stunden anerkannt.
        </p>`,
    image: "/assets/images/8C6B459A.jpg",
  },
  {
    uebertitel: "Workshop 3 Tage",
    titel: "Regeneration in der Natur",
    lead: `Die Natur bietet Raum für Erleben und Erfahren, für Entspannung, Regeneration
        und Ressourcenstärkung. Die Basis des Kurses bilden ein vertieftes Wahrnehmen
        der Umgebung, ein lebendiges Annähern und in Beziehungtreten mit der Umwelt. 
        Die bewusste Präsenz in der Gegenwart, unterstützt innere Ruhe und Wohlbefinden zu empfinden und daraus Kraft zu entwickeln. Gerade, wenn du durch Stress belastet bist oder dich in widrigen Lebensumständen befindest. Angeregt werden ein Zugang zum Lebendigen, die Regeneration von Aufmerksamkeits- und Konzentrationsvermögen und das Wohlbefinden.`,
    beschreibung: `<strong>Das Ansprechen aller Sinne durch die Natur, hilft Stress zu reduzieren und unterstützt Entspannung und Erholung. </strong>
        <br /><br />
        In diesem Kurs erfährst du, wie du in der Natur Kraft für deinen Alltag findest und wie du dir diese Momente bewahren kannst, damit sie später in herausfordernden Situationen zur Verfügung stehen.  
        <br /><br />
        Wir bewegen uns zwei Tage in einem Wechselspiel zwischen Wahrnehmung, dem Gestalten als Ausdruck und dem Austausch in einer kleinen Gruppe in der Natur.
        <br /><br />
        Die Auseinandersetzung mit einzelnen Pflanzen und der Natur findet über mal- und
        gestaltungstherapeutische Methoden, über imaginative Übungen und
        Achtsamkeitsanleitungen statt.  
        `,
    sidebar: `
        <p>
            <strong>Kursdaten</strong><br />
            Datum für 2025 folgt<br />
            3 Tage jeweils von 9 bis 16 Uhr<br />
        </p>
        <p>
            <strong>Kosten</strong><br />
            Fr. 450.— für alle drei Tage, inkl. Material (ohne Essen)
        </p>
        <p>
            <strong>Leitung</strong><br />
            strichpunkt | Evelyn Krapf-Schibli<br />
            Kunsttherapeutin iac Fachrichtung Mal- und Gestaltungstherapie<br />
			Psychosoziale Beratung MSc<br />
            Heilpädagogik MAS<br />
            Lehrerin für Textiles Werken
        </p>
        <p>
        <strong>Voraussetzungen</strong><br />
        keine
        </p>`,
    image: "/assets/images/A861EC27.jpg",
  },
  // {
  //     uebertitel: "Workshop 2 Halbtage",
  //     titel: "Pflanzenfarbe",
  //     lead: `Die Natur und der Garten bieten Raum für Erleben und Erfahren, für Entspannung, Regeneration und Ressourcenstärkung. Die Basis bilden ein vertieftes Wahrnehmen der Umgebung und einzelner Pflanzen, ein lebendiges Annähern und in Beziehungtreten mit der Umwelt.`,
  //     beschreibung: `AIn diesem Workshop steht eine intensive Auseinandersetzung mit einer selber ausgewählten Pflanze im Zentrum. Am ersten Nachmittag nehmen sich die Teilnehmer*innen Zeit, eine Pflanze mit all ihren Facetten zu erfahren, zu skizzieren und Informationen aus der Heilkunde und Mythologie gesammelt; am zweiten Nachmittag wird aus dieser Pflanze Farbe gewonnen und auf Papier angewendet.
  //     <br /><br />
  //    Eindrücke sammelst du während des Workshops auf einem Spaziergang in der Umgebung oder in unserem Naturgarten. Das Pflanzenmaterial sammelst du zwischen den beiden Kurshalbtagen selber.
  //     `,
  //     sidebar: `
  //     <p>
  //         <strong>Kursdaten</strong><br />
  //         Mi. 29. Juni und 6. Juli 2022<br />
  //         jeweils von 13.30 Uhr bis 17.30 Uhr<br />
  //         Mi. 24./31. August 2022<br />
  //         jeweils von 8.30 - 12.30 Uhr<br />
  //         Mi. 24./31.  August 2022<br />
  //         jeweils von 13.30 Uhr bis 17.30 Uhr
  //     </p>
  //     <p>
  //         <strong>Kurszeiten</strong><br />
  //         9 Uhr bis ca. 17.30 Uhr
  //     </p>
  //     <p>
  //         <strong>Kosten</strong><br />
  //         Fr. 160.—für beide Halbtage, inkl. Material und Pausenverpflegung
  //     </p>
  //     <p>
  //         <strong>Leitung</strong><br />
  //         strichpunkt | Evelyn Krapf-Schibli<br />
  //         Mal- und Gestaltungstherapeutin i.A.<br />
  // 		Psychosoziale Beratung MSc<br />
  //         Heilpädagogik MAS<br />
  //         Lehrerin für Textiles Werken
  //     </p>
  //     <p>
  //         <strong>Voraussetzung</strong><br />
  //         Keine
  //     </p>`,
  //     image: "/assets/images/A861EC27.jpg",
  // },
  {
    uebertitel: "Workshop 2 Tage",
    titel: "Pflanzendruckverfahren",
    lead: `Es gibt unterschiedliche manuelle Drucktechniken, die geeignet sind, um Pflanzen abzubilden. Einerseits mit aufgetragener Farbe, andererseits mit Pflanzenkontaktdruck, wobei die Farbe von der Pflanze selber abgegeben wird.`,
    beschreibung: `Mit allen im Kurs gezeigten Techniken entstehen interessante Druckergebnisse; der Abdruck auf dem Papier bringt Überraschungen und spannende Effekte. Bei einigen lassen sich weitere Schichten auftragen, die eher transparent oder dann deckend sind, wodurch einzelne Stellen zurücktreten oder stärker betont werden. Durch die Weiterbearbeitung mit Filz-/Farbstiften, Gouache etc. lassen sich Akzente setzen. 
        <br /><br />
        In diesem Workshop lernt ihr die Grundlagen unterschiedlicher Drucktechniken kennen und anwenden. Gezeigt werden verschiedene Techniken, wobei ihr selber entscheidet, wo ihr euch vertiefen möchtet. Es entstehen individuelle Bilder in kleinen Formaten. 
        <br /><br />
        Blätter und Blüten sammeln wir während des Workshops auf einem Spaziergang in der Umgebung.
        `,
    sidebar: `
        <p>
            <strong>Kursdaten</strong><br />
            Datum für 2025 folgt<br />
        </p>
        <p>
            <strong>Kosten</strong><br />
            Fr. 300.—für zwei Tage inkl. Material und Pausenverpflegung (ohne Mittagessen)
        </p>
        <p>
            <strong>Leitung</strong><br />
            strichpunkt | Evelyn Krapf-Schibli<br />
            Kunsttherapeutin iac Fachrichtung Mal- und Gestaltungstherapie<br />
			Psychosoziale Beratung MSc<br />
            Heilpädagogik MAS<br />
            Lehrerin für Textiles Werken
        </p>
        <p>
        <strong>Voraussetzungen</strong><br />
        keine
        </p>`,
    image: "/assets/images/525586A6.jpg",
  },
  {
    uebertitel: "Wanderung",
    titel: "Wildpflanzen & Kräuter",
    lead: `Wanderung über das Hochmoor Appenzell`,
    beschreibung: `Auf unserem Weg werden wir verschiedene Wildpflanzen im Spätsommer ansehen und deren Merkmale kennenlernen. Dazu gibt es Interessantes zur Verwendung in früheren Zeiten und in der Gegenwart zu hören und welche Wirkstoffe in den Pflanzen stecken.`,
    sidebar: `
        <p>
            <strong>Kursdatum</strong><br />
            Daten für 2025 folgen
        </p>
        <p>
            <strong>Kosten</strong><br />
            Fr. 120.—
        </p>
        <p>
            <strong>Leitung</strong><br />
            Evelyn Krapf-Schibli <br>
            Heilpädagogin MA <br>
            Psychosoziale Beratung MSc 
            Kunsttherapeutin iac Fachrichtung Mal- und Gestaltungstherapie <br>
            Gartenfrau mit Feldbotanikzertifikat 400<br>
            zur Zeit in Ausbildung Phytotherapie <br>
            Sortenerhalterin Pro Specie Rara <br>
            Mitglied Bioterra
        </p>`,
    image: "/assets/images/2BAAEB34.jpg",
  },
  // {
  //     uebertitel: "Workshop 1 Tag",
  //     titel: "Pflanzenfarben",
  //     lead: `Dieser Workshop eignet sich, um erste Erfahrungen mit Pflanzenfarben zu sammeln. Diese Technik ist sehr einfach mit verschiedenen Zielgruppen umzusetzen.`,
  //     beschreibung: `An diesem Tag werden wir Farben direkt aus gesammeltem und mitgebrachtem frischem oder getrocknetem Pflanzenmaterial hergestellt, mit natürlichen Zusätzen experimentiert und auf Papier ausprobiert. Die Farben werden wie Aquarellfarben angewendet und sind einige Tage haltbar.
  //     `,
  //     sidebar: `
  //     <p>
  //         <strong>Kursdaten</strong><br />
  //         5. September 2022<br />
  //         von 9 bis 16 Uhr<br />
  //         19. September 2022<br />
  //         von 9 bis 16 Uhr
  //     </p>
  //     <p>
  //         <strong>Kosten</strong><br />
  //         Fr. 140.—für 1 Kurstag, inkl. Material und Pausenverpflegung (ohne Mittagessen)
  //     </p>
  //     <p>
  //         <strong>Leitung</strong><br />
  //         strichpunkt | Evelyn Krapf-Schibli<br />
  //         Mal- und Gestaltungstherapeutin i.A.<br />
  // 		Psychosoziale Beratung MSc<br />
  //         Heilpädagogik MAS<br />
  //         Lehrerin für Textiles Werken
  //     </p>
  //     <p>
  //     <strong>Voraussetzungen</strong><br />
  //     keine
  //     </p>
  //     <p>
  //     <strong>Mitbringen</strong><br />
  //     Gesammeltes organisches Material entweder frisch oder getrocknet, z.B. gebrauchte Teebeutel, Avocadoschalen und -kerne, Rüstabfälle von Randen, Artischocken oder Rotkabis, abgeschnittene Blüten, Stengel, Blätter...
  //     </p>`,
  //     image: "/assets/images/2BAAEB34.jpg",
  // },
]
