import React from 'react'
import styled from "styled-components"
import DottedLineVertical from './DottedLineVertical'
import TwoThirdsGridReversed from './layout/TwoThirdsGridReversed'
import Text from "./text/Text"
import TextWithImage from "./text/TextWithImage"
import Button from "./Button"
import { breakpoints } from '../styles/variables'

const KursDiv = styled.div`
    h1 {
        margin-bottom: 18px;
    }
    h4 {
        margin-bottom: 4px;
    }

    margin-bottom: 25px;
    border-bottom: 1px solid black;

    @media screen AND (min-width: ${breakpoints.m}px) {
        h1 {
            margin-bottom: 40px;
        }  

         h4 {
            margin-bottom: 9px;
        }

        margin-bottom: 60px;

    }

    @media screen AND (min-width: ${breakpoints.l}px) {
        h1 {
            margin-bottom: 48px;
        }  

         h4 {
            margin-bottom: 12px;
        }

        margin-bottom: 120px;

    }
`


export default function Kurs({kurs}) {
    return (
        <KursDiv>
            <h4>{kurs.uebertitel}</h4>
            <h1>{kurs.titel}</h1>
            <TwoThirdsGridReversed>
                <TextWithImage kurs={kurs} />
                <DottedLineVertical />
                <Text>
                    <div dangerouslySetInnerHTML={{__html: kurs.sidebar}} />
                </Text>
            </TwoThirdsGridReversed>
            <div className="tdDisplayNone">
                <Button link="/kontakt" text="Jetzt anmelden" />
            </div>
        </KursDiv>
    )
}
